import React, {useState, useEffect} from "react"
// Components
import { Layout } from "../components/Layout"
import { Hero, MainServices, AboutUs, Gallery, StillQuestions } from "../components"
// Styles
import "../styles/index.sass"

const IndexPage = () => {
  const [color, setColor] = useState('');

  const scrollHandler = () => {
    const deviceHeight = window.innerHeight - 50;
    const scrollY = window.scrollY;

    if (scrollY >= deviceHeight) {
      setColor('#41444E')
    } else if (scrollY < deviceHeight) {
      setColor('white')
    }
  };

  const setInitColor = () => {
    const deviceHeight = window.innerHeight;
    const scrollY = window.scrollY;

    if (window.innerWidth < 768) {
      if (scrollY < deviceHeight) {
        setColor('white');
      }
    } else {
      return null;
    }
  }

  useEffect(() => {
    if (typeof window === 'undefined') return null;
    else {
      const init = setInitColor();
      if (init === null) return;
    }

    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }

  },[])


  return (
    <Layout
      title='Студия красоты "Lab Beauty" Ирины Лучистой | г. Бровары'
      description="Сделать маникюр, татуаж, педикюр, карбоновый пилинг, ультрафонофорез, удалить тату, ламинирование ресниц в г. Бровары"
      keywords="салон красоты бровары, студия красоты, татуаж бровары, перманентный макияж, маникюр, педикюр, ламинирование ресниц, lab beauty, ирина лучистая,
       карбоновый пилинг, ультрафонофорез, ламинирование ресниц, моделирование ногтей гелем, удаление папиллом, удаление ремувером, лазерное удаление тату и татуажа,
        аппаратная косметология, осветление пигментных пятен"
      headerColor={color}>
      <Hero />
      <AboutUs />
      <MainServices />
      <Gallery />
      <StillQuestions />
    </Layout>
  )
}

export default IndexPage
